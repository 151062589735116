import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/agent/workspace/s_provision-documentation_master/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const GifPlayer = makeShortcode("GifPlayer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
    <AnchorLink mdxType="AnchorLink">Server Workflows</AnchorLink>
    <AnchorLink mdxType="AnchorLink">Customer Workflow Profiles</AnchorLink>
    <AnchorLink mdxType="AnchorLink">Workflow Execution Details View</AnchorLink>
    </AnchorLinks>
    <h2>{`Server Workflows`}</h2>
    <h3>{`Ability to add, edit, remove workflow steps`}</h3>
    <p>{`Admins can now define the individual steps that make up a workflow within
the Provision Portal Admin UI`}</p>
    <GifPlayer color='dark' mdxType="GifPlayer">
      <img {...{
        "src": "/6a1cdd148d4b08c3d413d78d42d76c85/workflow-steps.gif",
        "alt": "workflow steps"
      }}></img>
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "69.09722222222221%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAABj0lEQVQ4y5VSy07DQAzMl/MHfAUSZ76AGyeuXFGlqhWQh5psstn3Dho3G9pS8bA0Srxrj8f2VvvNKzb7GruPDtv3Dt1hwDRNGMfxR6hxhNFa8m4fnnFz/4S7xxdUXduiPxyglJJA59wZLGHtl28trD25tw6TnjFOM/RsUG13b2jaDk3TCJyzAu+9IBAhwHt39ENACH69P555xOW8Ius8z0g5g0ZF9IuFmBBShpo0ejXCxwQXInLOgqKe6SklVGGtEjFMBuNskWJEjFECfAhwPsg9S7JwShmnlhcxQlhmQZJh6DEpheg9+r4XWGNgDLvQsizG0ViQPsUUE0IeCGFKEsBkVtRao21bOZNRWIuu62SGNOYwlnmlALES0uGWOT8SMlieh1JCxjgWISFJ+GVOmeU3wqKwLIT/dV3L5klKgmEYZAzFj0v7WeaafickAX0qZHDZKGMLisLinxGypdIyl0DC05bpsxDv/9SyqDEGMSUYF9anc6mi+Je4umVRuLy3/xpJz7Z8bUZF5eX/NbDlT2nHRZp24VKtAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "workflow steps",
          "title": "workflow steps",
          "src": "/static/30106965782055027235bec2624a7c43/3cbba/workflow-steps.png",
          "srcSet": ["/static/30106965782055027235bec2624a7c43/7fc1e/workflow-steps.png 288w", "/static/30106965782055027235bec2624a7c43/a5df1/workflow-steps.png 576w", "/static/30106965782055027235bec2624a7c43/3cbba/workflow-steps.png 1152w", "/static/30106965782055027235bec2624a7c43/9d3ac/workflow-steps.png 1633w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>
    </GifPlayer>
    <h3>{`Ability to create workflows with ordered steps.`}</h3>
    <p>{`Admins can now define a complete workflow that is made up of individual workflow
steps. Workflows can encompass any activity that would be carried out on a BD
product such as deploy software, perform a migration, etc.`}</p>
    <GifPlayer color='dark' mdxType="GifPlayer">
      <img {...{
        "src": "/28fd61f314ed77c514daff1ded0177c6/workflows.gif",
        "alt": "workflows"
      }}></img>
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "69.09722222222221%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAABj0lEQVQ4y5VSy07DQAzMl/MHfAUSZ76AGyeuXFGlqhWQh5psstn3Dho3G9pS8bA0Srxrj8f2VvvNKzb7GruPDtv3Dt1hwDRNGMfxR6hxhNFa8m4fnnFz/4S7xxdUXduiPxyglJJA59wZLGHtl28trD25tw6TnjFOM/RsUG13b2jaDk3TCJyzAu+9IBAhwHt39ENACH69P555xOW8Ius8z0g5g0ZF9IuFmBBShpo0ejXCxwQXInLOgqKe6SklVGGtEjFMBuNskWJEjFECfAhwPsg9S7JwShmnlhcxQlhmQZJh6DEpheg9+r4XWGNgDLvQsizG0ViQPsUUE0IeCGFKEsBkVtRao21bOZNRWIuu62SGNOYwlnmlALES0uGWOT8SMlieh1JCxjgWISFJ+GVOmeU3wqKwLIT/dV3L5klKgmEYZAzFj0v7WeaafickAX0qZHDZKGMLisLinxGypdIyl0DC05bpsxDv/9SyqDEGMSUYF9anc6mi+Je4umVRuLy3/xpJz7Z8bUZF5eX/NbDlT2nHRZp24VKtAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "workflows",
          "title": "workflows",
          "src": "/static/30106965782055027235bec2624a7c43/3cbba/workflows.png",
          "srcSet": ["/static/30106965782055027235bec2624a7c43/7fc1e/workflows.png 288w", "/static/30106965782055027235bec2624a7c43/a5df1/workflows.png 576w", "/static/30106965782055027235bec2624a7c43/3cbba/workflows.png 1152w", "/static/30106965782055027235bec2624a7c43/9d3ac/workflows.png 1633w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>
    </GifPlayer>
    <h2>{`Customer Workflow Profiles`}</h2>
    <p>{`Ability to create a deployment profile per customer per workflow and customize workflow step
parameters`}</p>
    <GifPlayer color='dark' mdxType="GifPlayer">
      <img {...{
        "src": "https://github-rd.carefusion.com/pages/devops/provision-documentation/releases/v2021.4.22/CreateWorkflowProfile.gif",
        "alt": "workflows"
      }}></img>
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "69.09722222222221%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAABj0lEQVQ4y5VSy07DQAzMl/MHfAUSZ76AGyeuXFGlqhWQh5psstn3Dho3G9pS8bA0Srxrj8f2VvvNKzb7GruPDtv3Dt1hwDRNGMfxR6hxhNFa8m4fnnFz/4S7xxdUXduiPxyglJJA59wZLGHtl28trD25tw6TnjFOM/RsUG13b2jaDk3TCJyzAu+9IBAhwHt39ENACH69P555xOW8Ius8z0g5g0ZF9IuFmBBShpo0ejXCxwQXInLOgqKe6SklVGGtEjFMBuNskWJEjFECfAhwPsg9S7JwShmnlhcxQlhmQZJh6DEpheg9+r4XWGNgDLvQsizG0ViQPsUUE0IeCGFKEsBkVtRao21bOZNRWIuu62SGNOYwlnmlALES0uGWOT8SMlieh1JCxjgWISFJ+GVOmeU3wqKwLIT/dV3L5klKgmEYZAzFj0v7WeaafickAX0qZHDZKGMLisLinxGypdIyl0DC05bpsxDv/9SyqDEGMSUYF9anc6mi+Je4umVRuLy3/xpJz7Z8bUZF5eX/NbDlT2nHRZp24VKtAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "workflows",
          "title": "workflows",
          "src": "/static/30106965782055027235bec2624a7c43/3cbba/workflows.png",
          "srcSet": ["/static/30106965782055027235bec2624a7c43/7fc1e/workflows.png 288w", "/static/30106965782055027235bec2624a7c43/a5df1/workflows.png 576w", "/static/30106965782055027235bec2624a7c43/3cbba/workflows.png 1152w", "/static/30106965782055027235bec2624a7c43/9d3ac/workflows.png 1633w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>
    </GifPlayer>
    <h2>{`Workflow Execution Details View`}</h2>
    <ul>
      <li parentName="ul">{`Ability to execute different workflows with corresponding deployment profiles`}</li>
      <li parentName="ul">{`An optional prompt message for steps that are NOT executed automatically`}</li>
      <li parentName="ul">{`The ability to view output of each task (sub-step) of a workflow step`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      